<template>
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Aside-->
        <!--end::Aside-->
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <!--begin::Header-->
          <div id="kt_header" class="header">
            <!--begin::Container-->
            <div class="container-fluid d-flex align-items-center flex-wrap justify-content-between" id="kt_header_container">
              <!--begin::Page title-->
              <div class="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                <!--begin::Heading-->
                <h1 class="d-flex flex-column text-dark fw-bolder my-0">Dashboard</h1>
                <!--end::Heading-->
              </div>
              <!--end::Page title=-->
              <!--begin::Wrapper-->
              <div class="d-flex d-lg-none align-items-center ms-n3 me-2">
                <!--begin::Aside mobile toggle-->
                <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                  <span class="svg-icon svg-icon-1 mt-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
											<path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
										</svg>
									</span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Aside mobile toggle-->
                <!--begin::Logo-->
                <a href="../../demo15/dist/index.html" class="d-flex align-items-center">
                  <img alt="Logo" src="../assets/media/logos/logo-2.svg" class="h-30px" />
                </a>
                <!--end::Logo-->
              </div>
            </div>
            <!--end::Container-->
          </div>
          <!--end::Header-->
          <!--begin::Content-->
          <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Container-->
            <div class="container-fluid" id="kt_content_container">
              <!--begin::Row-->
              <div class="row">
                <div class="col-12 my-3">
                    <div class="row">
                      <div class="col-md-1 col-3"><img class="me-1" style="width: 18px" src="/assets/images/profile-user.png" alt=""> {{$numberFormat(countUserOnline.TOTAL)}}</div>
                      <div class="col-md-1 col-3"><img class="me-1" style="width: 18px" src="/assets/images/web.png" alt=""> {{$numberFormat(countUserOnline.WEB)}}</div>
                      <div class="col-md-1 col-3"><img class="me-1" style="width: 18px" src="/assets/images/apple.png" alt=""> {{$numberFormat(countUserOnline.IOS)}}</div>
                      <div class="col-md-1 col-3"><img class="me-1" style="width: 18px" src="/assets/images/social.png" alt=""> {{$numberFormat(countUserOnline.ANDROID)}}</div>
                    </div>
                </div>
              </div>
              <div class="row g-5 g-lg-10">
                <!--begin::Col-->
                <div class="col-xxl-3 col-md-4 mb-xxl-10" v-for="(item, index) in this.items" :key="index">
                  <!--begin::Mixed Widget 17-->
                  <div class="card h-md-100">
                    <!--begin::Body-->
                    <div class="card-body pt-5">
                      <!--begin::Heading-->
                      <div class="d-flex flex-stack">
                        <!--begin::Title-->
                        <h4 class="fw-bolder text-gray-800 m-0 mb-5"> Ngày {{item.id}}</h4>
                        <!--end::Title-->
                        <!--begin::Menu-->
                      </div>
                      <!--end::Heading-->
                      <!--begin::Chart-->
                      <div class="d-flexw-100">
                        <div class="">
                          <b>Người dùng</b>  : {{item.countUser}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countUser - this.items[index + 1].countUser) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countUser - this.items[index + 1].countUser }}</b>
                            </span>
                            <span v-else-if="(item.countUser - this.items[index + 1].countUser) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countUser - this.items[index + 1].countUser) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Tác giả</b>  : {{item.countAuthor}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countAuthor - this.items[index + 1].countAuthor) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countAuthor - this.items[index + 1].countAuthor }}</b>
                            </span>
                            <span v-else-if="(item.countAuthor - this.items[index + 1].countAuthor) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countAuthor - this.items[index + 1].countAuthor) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Truyện</b>  : {{item.countStory}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countStory - this.items[index + 1].countStory) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countStory - this.items[index + 1].countStory }}</b>
                            </span>
                            <span v-else-if="(item.countStory - this.items[index + 1].countStory) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countStory - this.items[index + 1].countStory) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Chương</b>  : {{item.countChapter}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countChapter - this.items[index + 1].countChapter) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countChapter - this.items[index + 1].countChapter }}</b>
                            </span>
                            <span v-else-if="(item.countChapter - this.items[index + 1].countChapter) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countChapter - this.items[index + 1].countChapter) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Lượt đọc trả xu</b>  : {{$numberFormat(item.countView)}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countView - this.items[index + 1].countView) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ $numberFormat(item.countView - this.items[index + 1].countView) }}</b>
                            </span>
                            <span v-else-if="(item.countView - this.items[index + 1].countView) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{$numberFormat(Math.abs(item.countView - this.items[index + 1].countView)) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Lượt đọc không trả xu</b>  : {{$numberFormat(item.countViewNotPay)}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countViewNotPay - this.items[index + 1].countViewNotPay) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ $numberFormat(item.countViewNotPay - this.items[index + 1].countViewNotPay) }}</b>
                            </span>
                            <span v-else-if="(item.countViewNotPay - this.items[index + 1].countViewNotPay) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{$numberFormat(Math.abs(item.countViewNotPay - this.items[index + 1].countViewNotPay)) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Bình luận</b>  : {{item.countComment}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countComment - this.items[index + 1].countComment) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countComment - this.items[index + 1].countComment }}</b>
                            </span>
                            <span v-else-if="(item.countComment - this.items[index + 1].countComment) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countComment - this.items[index + 1].countComment) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Đánh dấu</b>  : {{item.countFavorite}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countFavorite - this.items[index + 1].countFavorite) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countFavorite - this.items[index + 1].countFavorite }}</b>
                            </span>
                            <span v-else-if="(item.countFavorite - this.items[index + 1].countFavorite) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countFavorite - this.items[index + 1].countFavorite) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Lượt cảm xúc</b>  : {{item.countReact}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countReact - this.items[index + 1].countReact) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countReact - this.items[index + 1].countReact }}</b>
                            </span>
                            <span v-else-if="(item.countReact - this.items[index + 1].countReact) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countReact - this.items[index + 1].countReact) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Lượt donate</b>  : {{item.countDonate}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countDonate - this.items[index + 1].countDonate) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countDonate - this.items[index + 1].countDonate }}</b>
                            </span>
                             <span v-else-if="(item.countDonate - this.items[index + 1].countDonate) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countDonate - this.items[index + 1].countDonate) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Tổng tiền donate</b>  : {{$numberFormat(item.sumAmountDonate)}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.sumAmountDonate - this.items[index + 1].sumAmountDonate) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ $numberFormat(item.sumAmountDonate - this.items[index + 1].sumAmountDonate) }}</b>
                            </span>
                            <span v-else-if="(item.sumAmountDonate - this.items[index + 1].sumAmountDonate) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{$numberFormat(Math.abs(item.sumAmountDonate - this.items[index + 1].sumAmountDonate)) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Lượt mua</b>  : {{item.countOrder}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countOrder - this.items[index + 1].countOrder) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countOrder - this.items[index + 1].countOrder }}</b>
                            </span>
                            <span v-else-if="(item.countOrder - this.items[index + 1].countOrder) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countOrder - this.items[index + 1].countOrder) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Số lần nạp</b>  : {{item.countWalletRequest}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countWalletRequest - this.items[index + 1].countWalletRequest) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countWalletRequest - this.items[index + 1].countWalletRequest }}</b>
                            </span>
                            <span v-else-if="(item.countWalletRequest - this.items[index + 1].countWalletRequest) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countWalletRequest - this.items[index + 1].countWalletRequest) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Tổng tiền nạp</b>  : {{$numberFormat(item.sumAmountRecharge)}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.sumAmountRecharge - this.items[index + 1].sumAmountRecharge) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ $numberFormat(item.sumAmountRecharge - this.items[index + 1].sumAmountRecharge) }}</b>
                            </span>
                             <span v-else-if="(item.sumAmountRecharge - this.items[index + 1].sumAmountRecharge) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{$numberFormat(Math.abs(item.sumAmountRecharge - this.items[index + 1].sumAmountRecharge)) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Follow</b>  : {{item.countFavorite}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.countFavorite - this.items[index + 1].countFavorite) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ item.countFavorite - this.items[index + 1].countFavorite }}</b>
                            </span>
                            <span v-else-if="(item.countFavorite - this.items[index + 1].countFavorite) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{Math.abs(item.countFavorite - this.items[index + 1].countFavorite) }}</b>
                            </span>
                          </span>
                          <br>
                          <b>Tổng xu trả</b>  : {{$numberFormat(item.sumAmountByDay)}}
                          <span v-if="index + 1 < this.items.length">
                            <span v-if="(item.sumAmountByDay - this.items[index + 1].sumAmountByDay) > 0">
                              <b class="text-success"><i class="bi bi-caret-up-fill text-success"></i>{{ $numberFormat(item.sumAmountByDay - this.items[index + 1].sumAmountByDay) }}</b>
                            </span>
                            <span v-else-if="(item.sumAmountByDay - this.items[index + 1].sumAmountByDay) === 0"></span>
                            <span v-else>
                              <b class="text-danger"><i class="bi bi-caret-down-fill text-danger"></i>{{$numberFormat(Math.abs(item.sumAmountByDay - this.items[index + 1].sumAmountByDay)) }}</b>
                            </span>
                          </span>
                        </div>
                      </div>
                      <!--end::Chart-->
                    </div>
                    <!--end::Body-->
                  </div>
                  <!--end::Mixed Widget 17-->
                </div>
                <!--end::Col-->
              </div>

            </div>
            <!--end::Container-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
</template>

<script>
import requestUtil from "@/utils/request-util";
export default {
  name: 'DashboardPage',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      items: [],
      countUserOnline: {},
    };
  },
  computed: {
  },
  methods: {
    async fetchItems() {
      try {
        // Gọi API để lấy dữ liệu
        const response = await requestUtil.get('/admin/statsByDay?sort=id,desc&size=7', this.filter);

        if (response.status === 200) {
          // Cập nhật categories từ API
          this.items = response.data.data;
        } else {
          this.$notify({
            title: 'Có lỗi xảy ra',
            type: 'warn',
          });
        }
      } catch (error) {
        this.$notify({
          title: 'Lỗi kết nối',
          type: 'error',
        });
      }
    }
  },
  watch: {
  },
  mounted() {
    this.fetchItems();
    this.fetchLixi();
  },
  created() {
      // Lắng nghe sự kiện 'message' từ server
    this.$socket.on('updateCountByPlatform', (data) => {
      this.countUserOnline = data;
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
